.App {
  text-align: center;
}

.uploadButton {
  padding: 20px 24px;
  background-color: #217a7d;
  color: #FFF;
  border: 0px;
  border-radius: 6px;
  cursor: pointer;
}

.inputUpload{
  display: flex;
  align-items: center;
}

.pushVideo{
  height: 80px;
  width: 100%;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  border-radius: 6px;
  background-color: rgb(153, 188, 163);
  color: #FFFFFF;
  font-weight: 600;
}

.pullVideo{
  height: 80px;
  width: 100%;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: #a7cf79;
  color: #FFFFFF;
  font-weight: 600;
}

.custom-input {
  width: 500px;
  height: 46px;
  background-color: #FFFFFF;
  border: 1px solid #E5E7EB;
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
  color: #1F2937;
  font-size: 15px;
  font-weight: normal;
  line-height: 18.15px;
  transition: all 0.3s ease;
}

.custom-input:focus {
  outline: none;
  border-color: #0f0f0f;
}

.custom-input:hover {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.1); /* Custom shadow */
}

.pauseResume{
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-items: center;
  align-items: center;
}


.pullVideoContainer {
  width: 100%;
  margin-top: 40px;
}

.fpUpload {
  background-color: beige;
  color: #217a7d;
  display: none;
}

.fpUpload1 {
  background-color: beige;
  color: #217a7d;
}

.webUpload {
  background-color: rgb(177, 168, 182);
  color: #747868;
  margin-top: 20px;
}

.pauseBtn{
  height: 50px;
  width:120px;
  border: 0px;
  background-color: rgb(194, 29, 29);
  cursor: pointer;
  color: #FFF;
  font-weight: 500;
  border-radius: 8px;
}

.pauseBtn:hover {
  opacity: 50%;
}

.resumeBtn {
  height: 50px;
  width:120px;
  border: 0px;
  background-color: rgb(96, 129, 47);
  color: #FFF;
  cursor: pointer;
  border-radius: 8px;
}

.resumeBtn:hover {
  opacity: 50%;
}

h1 {
  font-size: 24px;
  font-weight: 500;
  color: #222221;
}

.mainContainer {
  padding: 20px 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
